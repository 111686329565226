@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  text-decoration: none;
  color: #000;
}

p {
  line-height: 1.7;
}

ul {
  list-style: none;
}

li {
  line-height: 2.2;
}

h1,
h2,
h3 {
  font-weight: 600;
  margin-bottom: 10px;
}

.container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 60px;
}

.header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header ul li {
  margin-left: 20px;
}

.header ul li a {
  display: flex;
  align-items: center;
}

.header ul li a:hover {
  color: #777;
}

.header ul li a svg {
  margin-right: 5px;
}

.heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 50px;
  padding: 0 20px;
}

.heading p {
  color: #828282;
}

.goals {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
}

.goalslanding {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 10px, 0;
  gap: 10px;
}

.goalslanding .goal {
  background-color: #f4f4f4;
  padding: 10px 0 10px;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  align-content: center;
  align-items: center;
}

.goalslanding .goalabs {
  background-color: #cd90f7;
  padding: 10px 0 10px;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  align-content: center;
  align-items: center;
}

.goal {
  background-color: #f4f4f4;
  padding: 10px 0 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: baseline;
}

.goal > .goaldivs {
  display: flex;
  flex: 1;
  justify-content: center;
}
.goal .form {
  display: flex;
  flex: 3;
  justify-content: space-evenly;
}

.goal .close {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 35px;
  cursor: pointer;
  border-radius: 5px;
  border-width: 0px;
  background: rgba(237, 35, 35, 0.37);
  color: rgba(237, 35, 35, 0.37);
}

.goal .edit {
  display: none;
}

.goal:hover .edit {
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 35px;
  cursor: pointer;
  border-radius: 5px;
  border-width: 0px;
  border: none;
  background: rgba(62, 226, 17, 0.36);
}

.goalabs {
  background-color: #cd90f7;
  padding: 10px 0 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: baseline;
}

.goalabs > .goaldivs {
  display: flex;
  flex: 1;
  justify-content: center;
}

.goalabs .form {
  display: flex;
  flex: 3;
  justify-content: space-evenly;
}

.goalabs .close {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 35px;
  cursor: pointer;
  border-radius: 5px;
  border-width: 0px;
  background: rgba(237, 35, 35, 0.37);
  color: rgba(237, 35, 35, 0.37);
}

.goalabs .edit {
  display: none;
}

.goalabs:hover .edit {
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 35px;
  cursor: pointer;
  border-radius: 5px;
  border-width: 0px;
  border: none;
  background: rgba(62, 226, 17, 0.36);
}

.form,
.content {
  width: 70%;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

.form-group label {
  text-align: left;
  display: block;
  margin: 0 0 5px 3px;
}

.form-group .double {
  display: flex;
}

.double input[type="text"] {
  margin-right: 5px;
  justify-content: flex-start;
}
.double input[type="checkbox"] {
  margin-left: 5px;
  appearance: none;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  width: 10%;
  min-width: 10%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.double input[type="checkbox"]::before {
  content: "";
  width: 1.5em;
  height: 1.5em;
  border-radius: 5px;
  transform: scale(0);
  transform-origin: center;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 3em 3em #cd90f7;

  /* Windows High Contrast Mode */
  background-color: #cd90f7;
}
.double input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.btn {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons {
  padding-top: 20px;
  padding-bottom: 20px;
}

.btn svg {
  margin-right: 8px;
}

.btn-reverse {
  background: #fff;
  color: #000;
}

.btn-block {
  width: 100%;
  margin-bottom: 20px;
}

.btn:hover {
  transform: scale(0.98);
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .form {
    width: 90%;
  }

  .goalslanding {
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
  }

  .goal {
    margin: 0;
    font-size: x-small;
  }

  .goal .form {
    flex-direction: column;
  }

  .goaldivs {
    margin: 3px;
  }

  .goalabs {
    margin: 0;
    font-size: x-small;
  }

  .goalabs .form {
    flex-direction: column;
  }

  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}
